import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { catchError, combineLatest, EMPTY, map, Observable, switchMap, take } from 'rxjs';
import { Router } from '@angular/router';

import { Steps } from '@app/components/stepper/stepper.component';
import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import {
  PortalCreditCardNodeInput,
  PortalFeatures,
  PortalLeadNode,
  PortalOrderNode,
  PortalPassengerNodeInput,
} from '@app/services/api/api.types';
import { PopupsService } from '@app/ui/services/popups.service';
import { State, StateService } from '@app/services/state/state.service';
import { beforeUnloadHandler, mapPortalCreditCardNode } from '@app/utils/utils';
import { ApiService } from '@app/services/api/api.service';
import { CreditCardErrors } from '@app/forms/card-form/card-form.component';
import { clientBalanceOption, newCreditCardOption } from '@app/utils/constants';
import { Option } from '@app/forms/formly/formly-utils';
import { GetMaxBalanceAmountPipe } from '@app/pipes/get-max-balance-amount.pipe';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'overview-and-payment-page',
  templateUrl: './overview-and-payment-page.component.html',
  styleUrls: ['./overview-and-payment-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewAndPaymentPageComponent implements OnInit, OnDestroy {
  Steps = Steps;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;
  state: State;
  creditCards: Option[];
  stripeCreditCards: Option[];
  clientBalanceAmount: number;

  model: PortalCreditCardNodeInput = { cvv2: '' };
  errors: CreditCardErrors | null;

  formHasChanges: boolean;

  readAndAccept: boolean;
  readAndAcceptError: boolean;

  clientSecret: string | null = null;

  withStripe: boolean;

  @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<unknown>;

  constructor(
    private getMaxBalanceAmountPipe: GetMaxBalanceAmountPipe,
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private changeDetectorRef: ChangeDetectorRef,
    private popupsService: PopupsService,
    private stateService: StateService,
    private authService: AuthService,
    private apiService: ApiService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLead$ = combineLatest([
      this.portalLeadService.portalLead$,
      this.portalOrderService.portalOrder$,
    ]).pipe(
      map(([portalLead, portalOrder]) => {
        if (portalLead) {
          this.withStripe = portalLead.salesAgent.portalFeatures.includes(
            PortalFeatures.portal_stripe
          );
        }

        if (portalOrder) {
          this.portalOrder = portalOrder;
        }

        this.state = this.stateService.getState();

        const creditCards =
          this.stateService.getAuthorizedData().creditCards?.map(mapPortalCreditCardNode) || [];

        const clientBalanceAmount = this.stateService.getAuthorizedData().clientBalanceAmount || 0;

        if (creditCards.length || clientBalanceAmount) {
          creditCards.unshift(newCreditCardOption);
        }

        this.creditCards = creditCards;

        this.clientBalanceAmount = clientBalanceAmount;

        const stripeCreditCards =
          this.stateService.getAuthorizedData().stripeCreditCards?.map(mapPortalCreditCardNode) ||
          [];

        if (stripeCreditCards?.length) {
          stripeCreditCards.unshift(newCreditCardOption);
        }

        this.stripeCreditCards = stripeCreditCards;

        return portalLead;
      })
    );
  }

  valueChange() {
    this.formHasChanges = true;
    window.addEventListener('beforeunload', beforeUnloadHandler);
  }

  tryBack() {
    if (this.formHasChanges) {
      this.popupsService.showModal(this.confirmTemplate);
      return;
    }

    this.back();
  }

  back() {
    this.location.back();
  }

  stripeFormSubmit({
    saveCardToStripe,
    stripeCreditCardId,
  }: {
    saveCardToStripe: boolean;
    stripeCreditCardId: string;
  }) {
    const input = {
      portalLink: this.portalOrder.portalLink,
      passengers: this.state.passengers,
      payByStripe: true,
    };

    if (saveCardToStripe) {
      Object.assign(input, { saveCardToStripe });
    }

    if (stripeCreditCardId && stripeCreditCardId !== newCreditCardOption.value) {
      Object.assign(input, { stripeCreditCardId });
    }

    if (this.state.tipsAmount) {
      Object.assign(input, { tipsAmount: this.state.tipsAmount });
    }

    if (this.state.disruptionProtection) {
      Object.assign(input, { disruptionProtection: !!this.state.disruptionProtection });
    }

    if (this.state.cancelForAnyReason) {
      Object.assign(input, { cancelForAnyReason: !!this.state.cancelForAnyReason });
    }

    if (this.state.priceDropProtection) {
      Object.assign(input, { priceDropProtection: !!this.state.priceDropProtection });
    }

    if (this.authService.getUser()) {
      Object.assign(input, { anonymous: false });
    }

    return this.portalSubmitOption(input);
  }

  formSubmit(creditCard: PortalCreditCardNodeInput) {
    const input = {
      portalLink: this.portalOrder.portalLink,
      passengers: this.state.passengers,
      creditCard: undefined as PortalCreditCardNodeInput | undefined,
    };

    const isClientBalance =
      (creditCard.clientBalanceAmount && creditCard.clientBalanceAmount > 0) ||
      creditCard.id === clientBalanceOption.value;

    if (isClientBalance) {
      Object.assign(input, {
        clientBalanceAmount:
          creditCard.id === clientBalanceOption.value
            ? this.getMaxBalanceAmountPipe.transform(
                this.portalOrder.maxBalanceAmount,
                this.clientBalanceAmount,
                this.state
              )
            : creditCard.clientBalanceAmount,
      });
    }

    const isNewCreditCard = creditCard.id === newCreditCardOption.value || !creditCard.id;

    if (isNewCreditCard) {
      Object.assign(input, { creditCard });

      if (input.creditCard) {
        delete input.creditCard['id'];
        delete input.creditCard['clientBalanceAmount'];
        delete input.creditCard['cardAmount'];
      }
    }

    const isExistingCreditCard =
      creditCard.id &&
      creditCard.id !== newCreditCardOption.value &&
      creditCard.id !== clientBalanceOption.value;

    if (isExistingCreditCard) {
      Object.assign(input, {
        creditCard: {
          id: creditCard.id,
          cvv2: creditCard.cvv2,
        },
      });
    }

    if (!Object.keys(input.creditCard || {})?.length) {
      delete input['creditCard'];
    }

    if (this.state.tipsAmount) {
      Object.assign(input, { tipsAmount: this.state.tipsAmount });
    }

    if (this.state.disruptionProtection) {
      Object.assign(input, { disruptionProtection: !!this.state.disruptionProtection });
    }

    if (this.state.cancelForAnyReason) {
      Object.assign(input, { cancelForAnyReason: !!this.state.cancelForAnyReason });
    }

    if (this.state.priceDropProtection) {
      Object.assign(input, { priceDropProtection: !!this.state.priceDropProtection });
    }

    if (this.authService.getUser()) {
      Object.assign(input, { anonymous: false });
    }

    return this.portalSubmitOption(input);
  }

  portalSubmitOption(input: {
    passengers: PortalPassengerNodeInput[];
    portalLink: string;
    tipsAmount?: number;
    disruptionProtection?: boolean;
    cancelForAnyReason?: boolean;
    creditCard?: PortalCreditCardNodeInput;
    clientBalanceAmount?: number;
    priceDropProtection?: boolean;
    anonymous?: boolean;
    payByStripe?: boolean;
    saveCardToStripe?: boolean;
    stripeCreditCardId?: string;
  }) {
    return this.apiService
      .portalSubmitOption({ input })
      .pipe(
        take(1),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (input?.payByStripe && response?.ok && response?.stripeClientSecret) {
          this.clientSecret = response?.stripeClientSecret;
        } else if (!input?.payByStripe && response?.ok) {
          this.portalLeadService.getPortalLead({ portalLink: this.state.portalLink });

          this.continue();
        } else {
          this.errors = JSON.parse(response?.errors) as CreditCardErrors;
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  stripeFormSubmitCallback() {
    this.apiService
      .portalFinishPayment({ portalLink: this.state.portalLink })
      .pipe(
        take(1),
        switchMap(() => {
          this.portalLeadService.getPortalLead({ portalLink: this.state.portalLink });
          this.continue();
          return EMPTY;
        }),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  continue() {
    void this.router.navigate([
      `${this.state.portalLink}`,
      `${this.portalOrder.portalLink}`,
      `thank-you`,
    ]);
  }

  selectReadAndAccept(readAndAccept: boolean) {
    this.readAndAccept = readAndAccept;
    this.readAndAcceptError = false;
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
}
