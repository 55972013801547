import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Appearance, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { of, Subject, Subscription } from 'rxjs';

import { Formdata, Option } from '@app/forms/formly/formly-utils';
import { scrollTo } from '@app/utils/utils';
import { scrollToFirstError } from '@app/forms/formly/base-form/base-form';
import { errorsFormTexts } from '@app/forms/formly/validators/validators';
import { newCreditCardOption } from '@app/utils/constants';
import { PopupsService } from '@app/ui/services/popups.service';
import { throwSentryError } from '@app/utils/sentry';
import { AuthService, User } from '@app/services/auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeFormComponent implements OnInit, OnChanges, OnDestroy {
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;

  cardNumber: StripeCardNumberElement | null = null;
  cardNumberErrors: string | null = null;
  cardNumberComplete: boolean;

  cardExpiryErrors: string | null = null;
  cardExpiryComplete: boolean;

  cardCvcErrors: string | null = null;
  cardCvcComplete: boolean;

  isNewCreditCard = true;
  disabled: boolean;
  saveCardToStripe: boolean;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  model: Formdata = {};
  valueChanges$ = new Subscription();

  user$: Subject<User | null>;

  @Input() readAndAccept: boolean;
  @Input() clientSecret: string | null = null;
  @Input() portalLink: string;
  @Input() stripeCreditCards: Option[];

  @Output() back = new EventEmitter();
  @Output() formSubmit = new EventEmitter<Formdata>();
  @Output() formSubmitCallback = new EventEmitter<Formdata>();
  @Output() setReadAndAcceptError = new EventEmitter();

  @ViewChild('errorTemplate') errorTemplate: TemplateRef<unknown>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private popupsService: PopupsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.user$ = this.authService.user$;

    this.fields = this.getFields();

    this.initializeStripe();
  }

  initializeStripe() {
    void import('@stripe/stripe-js')
      .then((module) => module.loadStripe(environment.STRIPE_PUBLIC_KEY, { locale: 'en' }))
      .then((stripe) => {
        this.stripe = stripe;

        if (this.stripe) {
          this.elements = this.stripe.elements({ appearance });

          this.initCardNumberField();
          this.initCardExpiryField();
          this.initCardCvcField();
          this.initAddressField();
        }
      });
  }

  initCardNumberField() {
    if (!this.elements) return;

    const cardNumber = this.elements.create('cardNumber', { style });

    cardNumber.mount('#card-number');

    cardNumber.on('change', (event) => {
      if (event.error) {
        this.cardNumberErrors = event.error.message;
      } else {
        this.cardNumberErrors = null;
      }

      this.cardNumberComplete = event.complete;
      this.changeDetectorRef.detectChanges();
    });

    cardNumber.on('blur', () => {
      if (!this.cardNumberErrors && !this.cardNumberComplete) {
        this.cardNumberErrors = errorsFormTexts.cardNumberErrors;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.cardNumber = cardNumber;
  }

  initCardExpiryField() {
    if (!this.elements) return;

    const cardExpiry = this.elements.create('cardExpiry', { style });

    cardExpiry.mount('#card-expiry');

    cardExpiry.on('change', (event) => {
      if (event.error) {
        this.cardExpiryErrors = event.error.message;
      } else {
        this.cardExpiryErrors = null;
      }

      this.cardExpiryComplete = event.complete;

      this.changeDetectorRef.detectChanges();
    });

    cardExpiry.on('blur', () => {
      if (!this.cardExpiryErrors && !this.cardExpiryComplete) {
        this.cardExpiryErrors = errorsFormTexts.cardExpiryErrors;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  initCardCvcField() {
    if (!this.elements) return;

    const cardCvc = this.elements.create('cardCvc', { style });

    cardCvc.mount('#card-cvc');

    cardCvc.on('change', (event) => {
      if (event.error) {
        this.cardCvcErrors = event.error.message;
      } else {
        this.cardCvcErrors = null;
      }

      this.cardCvcComplete = event.complete;
      this.changeDetectorRef.detectChanges();
    });

    cardCvc.on('blur', () => {
      if (!this.cardCvcErrors && !this.cardCvcComplete) {
        this.cardCvcErrors = errorsFormTexts.cardCvcErrors;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  initAddressField() {
    if (!this.elements) return;

    const address = this.elements.create('address', { mode: 'billing' });

    address.mount('#address');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientSecret']?.currentValue) {
      void this.handlePayment();
    }
  }

  getFields() {
    return [
      {
        key: 'id',
        type: 'dropdown',
        className: 'mb-4',
        props: {
          placeholder: 'Payment method',
          options: of(this.stripeCreditCards),
        },
        defaultValue: newCreditCardOption.value,
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            let prevValue: unknown = field.formControl?.value;

            const sub = field.formControl?.valueChanges.subscribe((value) => {
              if (value === prevValue) return;

              prevValue = value;

              this.cardNumberErrors = null;
              this.cardExpiryErrors = null;
              this.cardCvcErrors = null;

              if (value === newCreditCardOption.value) {
                this.isNewCreditCard = true;

                const formValue = { id: newCreditCardOption.value };

                this.form.reset(formValue);

                setTimeout(() => this.initializeStripe());
              } else {
                this.isNewCreditCard = false;

                const foundCreditCard = this.stripeCreditCards?.find((p) => p.value === value);

                const formValue = { ...(foundCreditCard?.extra || {}) };

                setTimeout(() => this.form.patchValue(formValue)); // todo why doesnot work?
              }
            });

            this.valueChanges$.add(sub);
          },
        },
      },
      {
        key: 'holder',
        type: 'input',
        className: 'mb-4',
        props: {
          label: 'Name on Card',
          required: true,
          attributes: {
            autocomplete: 'cc-name',
          },
          disabled: true,
        },
        expressions: {
          hide: this.isStripeNewCreditCard.bind(this),
          disabled: this.isStripeNewCreditCard.bind(this),
        },
      },
      {
        fieldGroup: [
          {
            key: 'expiration',
            type: 'cc-expiration',
            className: 'mr-2 flex-1',
            props: {
              label: 'Expiration date',
              required: true,
              placeholder: 'MM/YY',
              disabled: true,
            },
            expressions: {
              hide: this.isStripeNewCreditCard.bind(this),
              disabled: this.isStripeNewCreditCard.bind(this),
            },
          },
        ],
        fieldGroupClassName: 'd-flex',
      },
    ];
  }

  async handlePayment() {
    if (!this.stripe || !this.clientSecret || !this.cardNumber) return;

    const addressElementValue = await this.elements?.getElement('address')?.getValue();
    const address = addressElementValue?.value;

    const options = this.isNewCreditCard
      ? {
          payment_method: {
            card: this.cardNumber,
            billing_details: { ...address },
          },
        }
      : {};

    const { error, paymentIntent } = await this.stripe.confirmCardPayment(
      this.clientSecret,
      options
    );

    if (error) {
      console.log(error.message || error);

      this.showErrorModal();

      throwSentryError('stripe_payment', error);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      this.formSubmitCallback.emit();
    }
  }

  submit() {
    let isValid =
      this.cardNumberComplete &&
      !this.cardNumberErrors &&
      this.cardExpiryComplete &&
      !this.cardExpiryErrors &&
      this.cardCvcComplete &&
      !this.cardCvcErrors;

    isValid = this.isNewCreditCard ? isValid : true;

    if (isValid && this.readAndAccept) {
      this.disabled = true;

      const options = {
        saveCardToStripe: this.saveCardToStripe,
        stripeCreditCardId: this.form.get('id')?.value,
      };

      this.formSubmit.emit(options);
    } else if (!isValid) {
      this.disabled = false;

      if (this.elements) {
        this.elements.getElement('cardNumber')?.focus();
        this.elements.getElement('cardExpiry')?.focus();
        this.elements.getElement('cardCvc')?.focus();
        setTimeout(() => this.elements?.getElement('cardCvc')?.blur(), 100); // todo why blur() doesnot work?
      }
      this.scrollToFirstError();
    } else {
      this.setReadAndAcceptError.emit(true);
      scrollTo('#read-and-accept');
    }

    const lead_portal_link = this.portalLink;
    window.track({ event_name: 'submit_stripe', lead_portal_link });
  }

  scrollToFirstError() {
    scrollToFirstError();
  }

  isStripeNewCreditCard() {
    const idControl = this.form.get('id');
    return idControl?.value === newCreditCardOption.value;
  }

  showErrorModal() {
    this.popupsService.showModal(this.errorTemplate);
  }

  refreshPage() {
    window.location.reload();
  }

  ngOnDestroy() {
    if (this.elements) {
      this.elements.getElement('cardNumber')?.destroy();
      this.elements.getElement('cardExpiry')?.destroy();
      this.elements.getElement('cardCvc')?.destroy();
      this.elements.getElement('address')?.destroy();
    }
  }
}

const style = {
  base: {
    color: '#0A1D3D',
    fontSize: '14px',
    lineHeight: '20px',
    '::placeholder': {
      color: '#898390',
    },
  },
  invalid: {
    color: '#0A1D3D',
  },
  complete: {
    color: '#0A1D3D',
  },
};

const appearance: Appearance = {
  disableAnimations: true,
  variables: {
    spacingUnit: '4px',
    fontFamily: '"Inter", "Roboto", arial, sans-serif',
  },
  rules: {
    '.Label': {
      color: '#898390',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '.Input': {
      color: '#0A1D3D',
      fontSize: '14px',
      lineHeight: '20px',
      border: '1px solid #E2E0E3',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0 0 #000000',
      transition: 'border-color 200ms ease-out, background-color 200ms ease-out',
    },
    '.Input:hover': {
      backgroundColor: '#F1F1F1',
    },
    '.Input:focus': {
      border: '1px solid #E2E0E3',
      boxShadow: '0 0 0 0 #000000',
    },
    '.Input--invalid': {
      color: '#0A1D3D',
      border: '1px solid #E52E2E',
      boxShadow: '0 0 0 0 #000000',
    },
    '.Input--invalid:hover': {
      backgroundColor: '#FDEEEE',
    },
  },
};
