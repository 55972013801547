import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription, map, of } from 'rxjs';

import { BaseFormComponent } from '@app/forms/formly/base-form/base-form';
import { convertEnumToOptions, Option } from '@app/forms/formly/formly-utils';
import { PortalGenderType } from '@app/services/api/api.types';
import { ApiService } from '@app/services/api/api.service';
import { errorsFormTexts } from '@app/forms/formly/validators/validators';
import { dropdownSeparatorValue } from '@app/ui/components/dropdown/dropdown.component';
import { newTravalerOption } from '@app/utils/constants';
import { PassengersErrors } from '@app/pages/passengers-page/passengers-page.component';

@Component({
  selector: 'passengers-form',
  templateUrl: './passengers-form.component.html',
  styleUrls: ['./passengers-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengersFormComponent
  extends BaseFormComponent<PassengersErrors | null>
  implements AfterViewInit, OnDestroy
{
  valueChanges$ = new Subscription();

  updating: boolean;

  @Input() passengersLabels: string[] = [];
  @Input() passengers: Option[] = [];
  @Input() portalLink: string;

  @Output() valueChange = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor(private apiService: ApiService) {
    super();
  }

  ngAfterViewInit() {
    const sub = this.form.valueChanges.subscribe(() => this.valueChange.emit());

    this.valueChanges$.add(sub);
  }

  override getFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'passengers',
        type: 'repeat-cards',
        fieldArray: {
          props: {
            labels: this.passengersLabels,
          },
          fieldGroup: [
            {
              key: 'id',
              type: 'dropdown',
              className: 'mb-4',
              props: {
                placeholder: 'Add a passenger',
                options: of(this.passengers),
              },
              defaultValue: newTravalerOption.value,
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const sub = field.formControl?.valueChanges.subscribe((value) => {
                    if (this.updating) return;

                    this.updating = true;

                    if (value === newTravalerOption.value) {
                      field.parent?.fieldGroup?.forEach(
                        (f) => f.key !== 'id' && f.formControl?.reset()
                      );
                    } else {
                      const foundPassenger = this.passengers?.find((p) => p.value === value);
                      const formValue = { ...(foundPassenger?.extra || {}) };

                      field.parent?.formControl?.patchValue(formValue);
                    }

                    this.updating = false;
                  });

                  this.valueChanges$.add(sub);
                },
              },
              expressions: {
                hide: () => !this.passengers?.length,
              },
            },
            {
              key: 'firstName',
              type: 'input',
              className: 'mb-4',
              props: {
                label: 'First name',
                required: true,
              },
              hooks: {
                onInit: this.fieldOnInit.bind(this),
              },
            },
            {
              key: 'middleName',
              type: 'input',
              className: 'mb-4',
              props: {
                label: 'Middle name',
              },
              hooks: {
                onInit: this.fieldOnInit.bind(this),
              },
            },
            {
              key: 'lastName',
              type: 'input',
              className: 'mb-4',
              props: {
                label: 'Last name',
                required: true,
              },
              hooks: {
                onInit: this.fieldOnInit.bind(this),
              },
            },
            {
              key: 'gender',
              type: 'radio',
              className: 'mb-4',
              props: {
                label: 'Gender',
                required: true,
                options: convertEnumToOptions(PortalGenderType),
              },
              defaultValue: PortalGenderType.MALE,
              hooks: {
                onInit: this.fieldOnInit.bind(this),
              },
            },
            {
              key: 'birthday',
              type: 'date',
              className: 'mb-4',
              props: {
                required: true,
              },
              validation: {
                messages: {
                  required: 'Please select a valid date of birth',
                  invalid: 'Please select a valid date of birth',
                },
              },
              hooks: {
                onInit: this.fieldOnInit.bind(this),
              },
            },
            {
              key: 'passportCountryId',
              type: 'dropdown',
              props: {
                label: 'Citizenship',
                placeholder: 'Select country',
                required: true,
                options: this.apiService.countries().pipe(
                  map((options) => {
                    const separatedOptions = ['US', 'GB', 'CA', 'AU'].map((code) => {
                      const index = options.findIndex(({ extra }) => extra?.code === code);
                      return options.splice(index, 1)[0];
                    });

                    return [...separatedOptions, { value: dropdownSeparatorValue }, ...options];
                  })
                ),
              },
              validation: {
                messages: {
                  required: 'Please select a country',
                },
              },
              hooks: {
                onInit: this.fieldOnInit.bind(this),
              },
            },
          ],
        },
      },
    ];
  }

  fieldOnInit(field: FormlyFieldConfig) {
    const sub = field.formControl?.valueChanges.subscribe(() => {
      if (this.updating) return;

      this.updating = true;

      const parent = field.parent as FormlyFieldConfig;
      parent.get && parent.get('id')?.formControl?.setValue(newTravalerOption.value);

      this.updating = false;
    });

    this.valueChanges$.add(sub);
  }

  override setErrors(errors: PassengersErrors) {
    if (errors.passengers?.length) {
      this.form.enable({ emitEvent: false });

      errors.passengers.forEach((error, i: number) => {
        if (error.birthday) {
          const passengersFormArray = this.form.get('passengers') as unknown as FormArray;
          passengersFormArray?.controls[i]?.get('birthday')?.setErrors({ backend: error.birthday });

          this.form.setErrors({ backend: errorsFormTexts.birthday });

          this.scrollToFirstError();
        }
      });
    }
  }

  override submit() {
    super.submit();

    const lead_portal_link = this.portalLink;
    window.track({ event_name: 'submit_passengers', lead_portal_link });
  }

  ngOnDestroy() {
    this.valueChanges$?.unsubscribe();
  }
}
