<div class="mb-4 mx-4 mx-md-0 d-flex align-items-center justify-content-between">
  <h2 class="semi-bold">Payment details</h2>

  <ng-container *ngIf="clientBalanceAmount && clientBalanceAmount > 0">
    <div
      *ngIf="isSplittedPayment$ | async; else splitTemplate"
      (click)="merge()"
      class="d-flex align-items-center px-md-4 cursor-pointer"
    >
      <img src="assets/icons/merge.svg" alt="merge" />
      <p class="caption ml-2">Back to Single Payment</p>
    </div>

    <ng-template #splitTemplate>
      <div (click)="split()" class="d-flex align-items-center px-md-4 cursor-pointer">
        <img src="assets/icons/split.svg" alt="split" />
        <p class="caption ml-2">Split between two methods</p>
      </div>
    </ng-template>
  </ng-container>
</div>

<info-block *ngIf="isSplittedPayment$ | async" class="mb-3"
  >Your payment will be split between two methods.</info-block
>

<div class="d-flex flex-column">
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>

  <div *ngIf="form.touched && form.errors" class="d-none d-md-block">
    <form-error
      *ngFor="let error of form.errors | keyvalue"
      [error]="error"
      class="mt-4"
      data-id="error"
    ></form-error>
  </div>
</div>

<div class="d-md-none p-fixed errors">
  <div *ngIf="form.touched && form.errors" class="mx-4">
    <form-error
      *ngFor="let error of form.errors | keyvalue"
      [error]="error"
      class="mb-2"
      data-id="error"
    ></form-error>
  </div>
</div>

<ng-content></ng-content>

<div class="mt-4 mt-md-6 mx-4 mx-md-0 d-flex flex-column-reverse flex-md-row">
  <button
    (click)="back.emit()"
    class="button secondary mt-4 mt-md-0 d-flex align-items-center justify-content-center"
    data-id="back"
  >
    <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
    Back
  </button>

  <button
    (click)="submit()"
    [disabled]="form.disabled"
    class="button ml-md-auto d-flex align-items-center justify-content-center"
    data-id="submit"
  >
    Pay
    <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
  </button>
</div>
