import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundPageComponent } from '@app/pages/not-found-page/not-found-page.component';
import { LoginPageComponent } from '@app/pages/login-page/login-page.component';
import { LogoutPageComponent } from '@app/pages/logout-page/logout-page.component';
import { OldLeadPageComponent } from '@app/pages/old-lead-page/old-lead-page.component';
import { LeadPageComponent } from '@app/pages/lead-page/lead-page.component';
import { LeadWrapperPageComponent } from '@app/pages/lead-wrapper-page/lead-wrapper-page.component';
import { ThankYouPageComponent } from '@app/pages/thank-you-page/thank-you-page.component';
import { PassengersPageComponent } from '@app/pages/passengers-page/passengers-page.component';
import { PortalOrderPageGuard } from '@app/guards/portal-order-page.guard';
import { PremiumDisruptionAssistancePageComponent } from '@app/pages/premium-disruption-assistance-page/premium-disruption-assistance-page.component';
import { CancelForAnyReasonPageComponent } from '@app/pages/cancel-for-any-reason-page/cancel-for-any-reason-page.component';
import { GratitudePageComponent } from '@app/pages/gratitude-page/gratitude-page.component';
import { OverviewAndPaymentPageComponent } from '@app/pages/overview-and-payment-page/overview-and-payment-page.component';
import { HomePageComponent } from '@app/pages/home-page/home-page.component';
import { PriceDropProtectionPageComponent } from '@app/pages/price-drop-protection-page/price-drop-protection-page.component';
import { BookingsAndTripsPageComponent } from '@app/pages/bookings-and-trips-page/bookings-and-trips-page.component';
import { QuotesPageComponent } from '@app/pages/quotes-page/quotes-page.component';
import { AuthGuard } from '@app/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      {
        path: 'quotes',
        component: QuotesPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bookings-and-trips',
        component: BookingsAndTripsPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: 'l/:lead-id',
    component: OldLeadPageComponent, // todo remove (simple redirect for the old leads)
  },
  {
    path: ':lead-id',
    component: LeadWrapperPageComponent,
    children: [
      {
        path: '',
        component: LeadPageComponent,
      },
      {
        path: ':option-id',
        canActivateChild: [PortalOrderPageGuard],
        children: [
          {
            path: '',
            redirectTo: 'passengers',
            pathMatch: 'full',
          },
          {
            path: 'passengers',
            component: PassengersPageComponent,
          },
          {
            path: 'premium-disruption-assistance',
            component: PremiumDisruptionAssistancePageComponent,
          },
          {
            path: 'cancel-for-any-reason',
            component: CancelForAnyReasonPageComponent,
          },
          {
            path: 'price-drop-protection',
            component: PriceDropProtectionPageComponent,
          },
          {
            path: 'gratitude',
            component: GratitudePageComponent,
          },
          {
            path: 'overview-and-payment',
            component: OverviewAndPaymentPageComponent,
          },
          {
            path: 'thank-you',
            component: ThankYouPageComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
