import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { catchError, EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Router } from '@angular/router';

import { ApiProtectedService } from '@app/services/api/api.protected.service';
import { ApiService } from '@app/services/api/api.service';
import { AuthService } from '@app/services/auth/auth.service';
import { PopupsService } from '@app/ui/services/popups.service';
import { StateService } from '@app/services/state/state.service';
import { PortalUserVerificationNode } from '@app/services/api/api.types';
import { passwordSalt } from '@app/utils/constants';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {
  HomePageTabs = HomePageTabs;
  CognitoHostedUIIdentityProvider = CognitoHostedUIIdentityProvider;

  authorizedData$: Observable<any | null>;

  tabs = [
    { key: HomePageTabs.Quotes, icon: 'plane', path: 'quotes' },
    { key: HomePageTabs.BookingsAndTrips, icon: 'checked_bag_full', path: 'bookings-and-trips' },
  ];

  email: string;

  activation: PortalUserVerificationNode;
  activationPassed: boolean;
  activationError: string | null;

  @ViewChild('continueWithEmailTemplate') continueWithEmailTemplate: TemplateRef<unknown>;
  @ViewChild('signInTemplate') signInTemplate: TemplateRef<unknown>;
  @ViewChild('activationCodeTemplate') activationCodeTemplate: TemplateRef<unknown>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private apiProtectedService: ApiProtectedService,
    private apiService: ApiService,
    private authService: AuthService,
    private popupsService: PopupsService,
    private stateService: StateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initSessionUser();
  }

  initSessionUser() {
    this.authorizedData$ = this.authService.hasSession().pipe(
      switchMap((hasSession) => {
        if (hasSession) {
          void this.router.navigate([`bookings-and-trips`]);
          return this.authService.initSessionUser(null);
        }

        this.showSignInModal();

        throw new Error('Invalid session: initSessionUser');
      }),
      switchMap(() => this.getAuthorizedData()),
      catchError((error: Error) => {
        console.log(error.message || error);
        return EMPTY;
      })
    );
  }

  getAuthorizedData() {
    const variables = { portalLink: '' };

    return this.apiProtectedService.authorizedDataHistory(variables).pipe(
      tap((data) =>
        this.stateService.patchAuthorizedData({
          quotes: data.quotes,
          upcomingTrips: data.upcomingTrips,
          pastTrips: data.pastTrips,
        })
      )
    );
  }

  showSignInModal() {
    this.popupsService.showModal(this.signInTemplate);
  }

  hideModal() {
    this.popupsService.hideLast();
  }

  continueWith(provider: CognitoHostedUIIdentityProvider) {
    const url = `${window.location.href}/bookings-and-trips`;
    void this.authService.federatedSignIn(provider, url);
  }

  continueWithEmail() {
    this.hideModal();
    this.popupsService.showModal(this.continueWithEmailTemplate);
  }

  sendEmail(email: string) {
    this.activationError = null;
    this.email = email;

    const portalLink = '';

    const variables = { portalLink, email };

    this.apiService
      .sendPasswordToEmail(variables)
      .pipe(take(1))
      .subscribe(({ errors }) => {
        if (errors?.length) {
          this.activationError = errors[0].message;
        } else {
          this.hideModal();

          this.activation = {
            emailMasked: this.email,
            emailSentAt: new Date().toUTCString(),
          };

          this.popupsService.showModal(this.activationCodeTemplate);
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  validateNewEmailCode(password: string) {
    this.activationPassed = false;
    this.activationError = null;

    void this.authService
      .signIn(this.email, `${password}${atob(passwordSalt)}`)
      .pipe(
        take(1),
        map(() => {
          this.activationPassed = true;

          setTimeout(() => window.location.reload(), 1000);

          this.changeDetectorRef.detectChanges();
        }),
        catchError((error: Error) => {
          console.log(error.message || error);

          this.activationError = error.message;

          this.changeDetectorRef.detectChanges();

          return EMPTY;
        })
      )
      .subscribe();
  }

  signOut() {
    void this.authService.signOut();
  }
}

enum HomePageTabs {
  Quotes = 'Quotes',
  BookingsAndTrips = 'Bookings and Trips',
}
