<ng-container *ngIf="portalLead$ | async as portalLead">
  <flight-info [portalOrder]="portalOrder" class="mx-4 mx-md-0"></flight-info>

  <stepper [step]="Steps.TravelerDetails" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

  <div class="my-4 my-md-8 d-flex flex-column flex-md-row">
    <div class="flex-1">
      <price-drop-select
        (selectPriceDropProtection)="selectPriceDropProtection($event)"
        [priceDropProtection]="priceDropProtection"
        [option]="portalOrder"
      ></price-drop-select>

      <div class="d-none d-md-flex mt-6">
        <button
          (click)="tryBack()"
          class="button secondary d-flex align-items-center"
          data-id="back"
        >
          <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
          Back
        </button>

        <button
          (click)="submit()"
          class="button ml-auto d-flex align-items-center"
          data-id="submit"
        >
          Continue
          <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
        </button>
      </div>
    </div>

    <div class="d-none d-md-block ml-md-4 summary">
      <h2 class="semi-bold mb-4">Price summary</h2>

      <price-summary
        [option]="portalOrder"
        [disruptionProtection]="state.disruptionProtection"
        [tipsAmount]="state.tipsAmount"
        [cancelForAnyReason]="state.cancelForAnyReason"
        [priceDropProtection]="priceDropProtection"
      ></price-summary>
    </div>

    <div class="d-flex flex-column d-md-none mt-4 mx-4">
      <button
        (click)="submit()"
        data-id="submit"
        class="button d-flex align-items-center justify-content-center"
      >
        Continue
        <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
      </button>

      <button
        (click)="tryBack()"
        class="button secondary mt-4 d-flex align-items-center justify-content-center"
        data-id="back"
      >
        <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
        Back
      </button>
    </div>
  </div>
</ng-container>

<ng-template #confirmTemplate>
  <go-back-confirm (back)="back()"></go-back-confirm>
</ng-template>
