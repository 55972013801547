<ng-container *ngIf="portalLead$ | async as portalLead">
  <flight-info [portalOrder]="portalOrder" class="mx-4 mx-md-0"></flight-info>

  <stepper [step]="Steps.ThankYou" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

  <card class="my-4 my-md-8 flex-column align-items-center card">
    <img src="assets/icons/done.svg" alt="done" width="48" height="48" />

    <ng-container *ngIf="portalOrder.isDemo; else defaultTemplate">
      <h2 class="semi-bold mt-6 text-align-center">Thank you for your booking request</h2>

      <p class="mt-3 text-align-center">
        We’re currently reconfirming the availability of your chosen options. Your travel<br
          class="d-none d-md-inline"
        />agent will be in touch with you shortly to provide further instructions. If you have<br
          class="d-none d-md-inline"
        />any questions or just want to share your pre-travel excitement, we’re here for you.
      </p>

      <button (click)="goBack()" class="button mt-6" data-id="thank-you">Complete</button>
    </ng-container>

    <ng-template #defaultTemplate>
      <h2 class="semi-bold mt-6 text-align-center">We’re working on your booking</h2>

      <p class="mt-3 text-align-center">
        Thanks a ton for your patience! If you have any questions or just want to share<br
          class="d-none d-md-inline"
        />
        your pre-travel excitement, we're here for you. Safe trip and happy travels!
      </p>

      <button (click)="goBack()" class="button mt-6" data-id="thank-you">Thank you</button>
    </ng-template>
  </card>
</ng-container>
