<div class="mb-4 mx-4 mx-md-0 d-flex align-items-center justify-content-between">
  <h2 class="semi-bold">Payment details</h2>
</div>

<card class="d-flex flex-column p-4">
  <form [ngClass]="{ 'd-none': !stripeCreditCards.length }" [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>

  <div [ngClass]="{ 'd-none': !isNewCreditCard }">
    <p class="text-jazlyn-700 mb-1">Debit/Credit card number</p>
    <div [ngClass]="{ 'error': cardNumberErrors }" class="input" id="card-number"></div>
    <p *ngIf="cardNumberErrors" class="error mt-1">{{ cardNumberErrors }}</p>

    <div class="d-flex w-100 mt-4">
      <div class="d-flex flex-column mr-2 w-100">
        <p class="text-jazlyn-700 mb-1">Expiration date</p>
        <div [ngClass]="{ 'error': cardExpiryErrors }" class="input" id="card-expiry"></div>
        <p *ngIf="cardExpiryErrors" class="error mt-1">{{ cardExpiryErrors }}</p>
      </div>

      <div class="d-flex flex-column ml-2 w-100">
        <p class="text-jazlyn-700 mb-1">Security code</p>
        <div [ngClass]="{ 'error': cardCvcErrors }" class="input" id="card-cvc"></div>
        <p *ngIf="cardCvcErrors" class="error mt-1">{{ cardCvcErrors }}</p>
      </div>
    </div>

    <div class="mt-4" id="address"></div>

    <checkbox
      *ngIf="user$ | async as user"
      (click)="saveCardToStripe = !saveCardToStripe"
      [selected]="saveCardToStripe"
      data-id="save-stripe-credit-card"
      class="mt-4"
    >
      <div class="d-flex align-items-center">
        <p class="caption text-jazlyn-700">Remember this card for future use</p>
      </div>
    </checkbox>
  </div>
</card>

<ng-content></ng-content>

<div class="mt-4 mt-md-6 mx-4 mx-md-0 d-flex flex-column-reverse flex-md-row">
  <button
    (click)="back.emit()"
    class="button secondary mt-4 mt-md-0 d-flex align-items-center justify-content-center"
    data-id="back"
  >
    <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
    Back
  </button>

  <button
    (click)="submit()"
    [disabled]="disabled"
    class="button ml-md-auto d-flex align-items-center justify-content-center"
    data-id="submit"
  >
    Pay
    <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
  </button>
</div>

<ng-template #errorTemplate>
  <error-modal (refreshPage)="refreshPage()"></error-modal>
</ng-template>
