import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermOfUseComponent {
  @Input() disclaimersHtml: string;
  @Input() disclaimersCheckmarkHtml: string;
  @Input() readAndAccept: boolean;
  @Input() readAndAcceptError: boolean;

  @Output() selectReadAndAccept = new EventEmitter();
}
