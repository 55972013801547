<ng-container *ngIf="portalLead$ | async as portalLead">
  <ng-container *ngIf="portalLead.salesAgent; else searchingTravelExpert">
    <ng-container *ngIf="portalLead.flightRequest; else travelExpertInfo">
      <flight-info [portalLead]="portalLead" class="mx-4 mx-md-0"></flight-info>

      <stepper [step]="Steps.PickYourFlight" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

      <div class="my-4 my-md-8">
        <portal-option
          *ngFor="let option of portalLead.flightRequest.publishedOptions?.edges | extractNodes"
          (requestToBook)="requestToBook(option)"
          [option]="option"
          [portalLink]="state.portalLink"
          [withHiddenFlightsV2]="withHiddenFlightsV2"
          class="mt-4"
          data-id="option"
          attr.track-scroll="{
            'event_name': 'quote_overview_seen',
            'lead_portal_link': '{{ state.portalLink }}',
            'option_portal_link': '{{ option.portalLink }}'
          }"
        ></portal-option>

        <p class="mt-4 mt-md-6 mx-4 mx-md-10 pb-md-7 text-align-center text-jazlyn-700">
          All currency exchange rates calculated as of
          <span data-id="created-at"
            >{{ portalLead.flightRequest.createdAt | date: 'MMM d, yyyy' }}</span
          >, based on rates provided by Global Distribution System. Please note, all transactions
          are to be charged in USD
        </p>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #searchingTravelExpert>
    <div class="my-4 my-md-8">
      <searching-for-a-travel-expert class="mt-4 mt-md-8"></searching-for-a-travel-expert>
    </div>
  </ng-template>

  <ng-template #travelExpertInfo>
    <travel-expert-info
      [portalLead]="portalLead"
      [portalLink]="state.portalLink"
    ></travel-expert-info>
  </ng-template>
</ng-container>

<ng-template #hiddenFlightsTemplate>
  <hidden-flights-modal
    (hiddenFlightsPresented)="getPortalLead()"
    (requestToBook)="submitRequestToBook(); hideModal()"
    (hideModal)="hideModal()"
    [option]="portalOrder"
  ></hidden-flights-modal>
</ng-template>

<ng-template #signInTemplate>
  <sign-in-modal
    (hideModal)="hideModal()"
    (continueWithGoogle)="continueWith(CognitoHostedUIIdentityProvider.Google)"
    (continueWithFacebook)="continueWith(CognitoHostedUIIdentityProvider.Facebook)"
    (continueWithEmail)="continueWithEmail()"
  ></sign-in-modal>
</ng-template>

<ng-template #askToSignInTemplate>
  <ask-to-sign-in-modal
    (hideModal)="hideModal()"
    (continueAsGuest)="submitRequestToBook(); hideModal()"
    (showSignInModal)="showSignInModal()"
  ></ask-to-sign-in-modal>
</ng-template>

<ng-template #errorTemplate>
  <error-modal (refreshPage)="refreshPage()"></error-modal>
</ng-template>

<ng-template #continueWithEmailTemplate>
  <continue-with-email-modal
    (hideModal)="hideModal()"
    (sendEmail)="sendEmail($event)"
    [activationError]="activationError"
  ></continue-with-email-modal>
</ng-template>

<ng-template #activationCodeTemplate>
  <activation-code-modal
    (validateCode)="validateNewEmailCode($event)"
    (sendActivationEmail)="sendEmail(email)"
    (hideModal)="hideModal()"
    [activation]="activation"
    [activationPassed]="activationPassed"
    [activationError]="activationError"
  ></activation-code-modal>
</ng-template>
