<card (click)="toggleDetails()" class="card">
  <div class="d-flex flex-column flex-md-row w-100">
    <div class="d-flex flex-column flex-1">
      <div class="d-flex align-items-center px-4 py-4 px-md-6 title">
        <p class="semi-bold" data-id="option-title">Option {{ option.alphaName }}</p>
        <ng-container *ngIf="option.portalDescription">
          <dot-separator class="mx-2"></dot-separator>
          <p data-id="option-description">{{ option.portalDescription }}</p>
        </ng-container>
      </div>

      <div class="d-flex flex-column px-4 py-4 px-md-6">
        <ng-container *ngFor="let itinerary of option.flightItineraries">
          <option-itinerary
            *ngIf="itinerary.segments.length"
            [itinerary]="itinerary"
            class="itinerary"
          ></option-itinerary>

          <hr *ngIf="itinerary.segments.length" class="my-4 d-md-none separator" />
        </ng-container>

        <div
          *ngIf="option.hotelBookings.length || (option.hasHiddenCityTicketing && withHiddenFlightsV2) || (option.hasThrowawayTicketing && withHiddenFlightsV2)"
          class="d-flex flex-column flex-md-row mt-4"
        >
          <div
            *ngIf="option.hotelBookings.length"
            [tooltip]="hotelBookingsTooltip"
            [modal]="hotelBookingsTooltip"
            class="d-flex align-items-center"
            data-id="hotel-badge"
          >
            <img src="assets/icons/hotel_full.svg" alt="hotels" />
            <p class="caption ml-1 text-sun-crete-700">Hotel stay</p>
          </div>

          <span
            *ngIf="option.hotelBookings?.length && ((option.hasHiddenCityTicketing && withHiddenFlightsV2) || (option.hasThrowawayTicketing && withHiddenFlightsV2))"
            class="d-none d-md-flex align-items-center mx-2"
            ><dot-separator></dot-separator
          ></span>

          <div
            *ngIf="option.hasHiddenCityTicketing && withHiddenFlightsV2"
            [ngClass]="{ 'mt-2 mt-md-0': option.hotelBookings.length }"
            [tooltip]="hiddenCityTooltip"
            [modal]="hiddenCityTooltip"
            class="d-flex align-items-center"
            data-id="hidden-city"
          >
            <img src="assets/icons/star-blue.svg" alt="star-blue" />
            <p class="caption ml-1 text-bleu-de-france-600">Hidden City</p>
          </div>

          <span
            *ngIf="option.hasHiddenCityTicketing && option.hasThrowawayTicketing && withHiddenFlightsV2"
            class="d-none d-md-flex align-items-center mx-2"
            ><dot-separator></dot-separator
          ></span>

          <div
            *ngIf="option.hasThrowawayTicketing && withHiddenFlightsV2"
            [ngClass]="{ 'mt-2 mt-md-0': option.hotelBookings.length || (option.hasHiddenCityTicketing && withHiddenFlightsV2) }"
            [tooltip]="throwawayTicketingTooltip"
            [modal]="throwawayTicketingTooltip"
            class="d-flex align-items-center"
            data-id="throwaway-ticketing"
          >
            <img src="assets/icons/star-blue.svg" alt="star-blue" />
            <p class="caption ml-1 text-bleu-de-france-600">Throwaway Ticketing</p>
          </div>

          <ng-template #hotelBookingsTooltip>
            <div class="px-4 py-10 px-md-0 py-md-0">
              <hotel-bookings-tooltip
                [hotelBookings]="option.hotelBookings"
              ></hotel-bookings-tooltip>
            </div>
          </ng-template>

          <ng-template #hiddenCityTooltip>
            <div class="px-4 px-md-0 py-10 py-md-0">
              <hidden-city-tooltip [option]="option"></hidden-city-tooltip>
            </div>
          </ng-template>

          <ng-template #throwawayTicketingTooltip>
            <div class="px-4 px-md-0 py-10 py-md-0">
              <throwaway-ticketing-tooltip [option]="option"></throwaway-ticketing-tooltip>
            </div>
          </ng-template>
        </div>

        <ng-container>
          <p *ngIf="showedDetails; else showDetailsTemplate" class="text-bleu-de-france-600 mt-4">
            Hide details
          </p>
        </ng-container>

        <ng-template #showDetailsTemplate>
          <p class="text-bleu-de-france-600 mt-4">Show details</p>
        </ng-template>
      </div>
    </div>

    <div class="d-flex flex-column px-4 py-4 px-md-6 book">
      <product-badge
        *ngIf="option.cashback?.eligible"
        class="mb-4 ml-auto ml-md-0"
        data-id="cashback"
      >
        <img src="assets/icons/paid.svg" alt="paid" width="20" height="20" />
        <p class="ml-2 text-green-800">Cashback {{ option.cashback.amount | portalCurrency }}</p>
      </product-badge>

      <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>

      <div class="d-flex mt-6">
        <div
          [ngClass]="option.bookingStatus === PortalBookingStatusTypeEnum.Option ? 'w-100' : 'ml-auto ml-md-0 mr-md-auto'"
        >
          <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
</card>

<div *ngIf="showedDetails" (click)="toggleDetails()" class="details p-6 pt-9">
  <portal-option-details
    [option]="option"
    [portalLink]="portalLink"
    [withHiddenFlightsV2]="withHiddenFlightsV2"
  ></portal-option-details>

  <card *ngIf="option.hotelBookings?.length" class="mt-4 d-flex">
    <hotel-bookings [hotelBookings]="option.hotelBookings" class="w-100"></hotel-bookings>
  </card>

  <div class="mt-4 d-flex align-items-center justify-content-between">
    <p class="text-bleu-de-france-600">Hide details</p>

    <div class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>

      <div class="ml-6">
        <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #pricesTooltip>
  <div class="px-4 px-md-0 py-10 py-md-0">
    <prices-tooltip [option]="option"></prices-tooltip>
  </div>
</ng-template>

<ng-template #defaultTemplate>
  <p class="ml-auto ml-md-0 text-jazlyn-700">
    {{ option.bookingStatus === PortalBookingStatusTypeEnum.InProgress || option.bookingStatus ===
    PortalBookingStatusTypeEnum.Issued ? 'Trip total' : 'Per traveler' }}
  </p>
</ng-template>

<ng-template #priceTemplate>
  <div class="d-flex flex-row flex-md-column mt-md-auto">
    <div class="d-flex flex-column">
      <save-badge *ngIf="(option.savePrice || 0) > 0" data-id="save-price"
        >Save {{ option.savePrice | portalCurrency }}</save-badge
      >
      <p
        *ngIf="option.onlinePrice > 0"
        class="d-md-none caption mt-auto text-jazlyn-700 text-through"
        data-id="m-online-price"
      >
        {{ option.onlinePrice | portalCurrency }}
      </p>
    </div>

    <div class="d-flex flex-1 flex-column mt-md-1">
      <div class="ml-auto ml-md-0 d-flex">
        <h2 class="semi-bold align-items-baseline">
          <span data-id="sold-amount"
            >{{ (option.totalPassengers ? option.soldAmountPerAdult : option.soldAmount) |
            portalCurrency }}</span
          >
          <p
            *ngIf="option.onlinePrice > 0"
            class="d-none d-md-flex caption ml-2 text-jazlyn-700 text-through"
            data-id="online-price"
          >
            {{ option.onlinePrice | portalCurrency }}
          </p>
        </h2>
      </div>

      <div class="d-flex align-items-center">
        <ng-container *ngIf="option.totalPassengers; else defaultTemplate">
          <p class="ml-auto ml-md-0 text-jazlyn-700">Per adult</p>
          <img
            [tooltip]="pricesTooltip"
            [modal]="pricesTooltip"
            src="assets/icons/info.svg"
            width="20"
            height="20"
            alt="info"
            class="ml-1"
            data-id="prices"
          />
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttonTemplate>
  <ng-container [ngSwitch]="option.bookingStatus">
    <button
      *ngSwitchCase="PortalBookingStatusTypeEnum.Option"
      (click)="$event.stopPropagation(); requestToBook.emit()"
      class="button w-100"
      data-id="request-to-book"
      id="submit-{{ option.portalLink }}"
    >
      Select
    </button>

    <booking-status
      *ngSwitchCase="PortalBookingStatusTypeEnum.Booked"
      [status]="PortalBookingStatusTypeEnum.Booked"
    ></booking-status>

    <booking-status
      *ngSwitchCase="PortalBookingStatusTypeEnum.InProgress"
      [status]="PortalBookingStatusTypeEnum.InProgress"
    ></booking-status>

    <booking-status
      *ngSwitchCase="PortalBookingStatusTypeEnum.Issued"
      [status]="PortalBookingStatusTypeEnum.Issued"
    ></booking-status>
  </ng-container>
</ng-template>

<ng-template #optionDetailsTemplate>
  <option-details-modal
    (requestToBook)="requestToBook.emit()"
    [option]="option"
    [portalLink]="portalLink"
  ></option-details-modal>
</ng-template>
