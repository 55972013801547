<card class="d-flex flex-column p-4">
  <checkbox
    (click)="selectReadAndAccept.emit(!readAndAccept)"
    [selected]="readAndAccept"
    [error]="readAndAcceptError"
    id="read-and-accept"
    data-id="read-and-accept"
    class="read-and-accept"
  >
    <div class="d-flex flex-column">
      <div
        [innerHtml]="disclaimersHtml || ''"
        class="disclaimers scrollable"
        data-id="disclaimers-html"
      ></div>

      <div
        [innerHtml]="disclaimersCheckmarkHtml || ''"
        class="mt-3 disclaimers"
        data-id="disclaimers-checkmark-html"
      ></div>
    </div>
  </checkbox>

  <div class="d-flex align-items-center mt-3">
    <img src="assets/icons/lock.svg" width="20" height="20" alt="lock" class="mb-auto" />

    <p class="ml-2 caption text-jazlyn-700">
      We use secure transmission to protect your personal information.
    </p>
  </div>
</card>
