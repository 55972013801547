import { Pipe, PipeTransform } from '@angular/core';

import { State } from '@app/services/state/state.service';

@Pipe({
  name: 'getMaxBalanceAmount',
})
export class GetMaxBalanceAmountPipe implements PipeTransform {
  transform(maxBalanceAmount = 0, clientBalanceAmount = 0, state: State) {
    const maxBalanceAmountWithAdditionalProducts =
      maxBalanceAmount +
      (state.disruptionProtection || 0) +
      (state.tipsAmount || 0) +
      (state.cancelForAnyReason || 0) +
      (state.priceDropProtection || 0);

    if (maxBalanceAmountWithAdditionalProducts > clientBalanceAmount) {
      return clientBalanceAmount;
    }

    return maxBalanceAmountWithAdditionalProducts;
  }
}
