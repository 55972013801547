<ng-container *ngIf="authorizedData$ | async as authorizedData; else loadingTemplate">
  <header (signIn)="showSignInModal()" (signOut)="signOut()" class="header"></header>

  <div class="mx-auto pt-4 pt-md-10 content">
    <div class="d-flex mx-4 mx-md-0">
      <tab
        *ngFor="let tab of tabs"
        [routerLink]="['/', tab.path]"
        [routerLinkActive]="'active'"
        data-id="tab"
      >
        <img
          src="assets/icons/{{ tab.icon }}.svg"
          width="20"
          height="20"
          alt="{{ tab.icon }}"
          class="mr-2"
        />
        <p class="caption semi-bold">{{ tab.key }}</p>
      </tab>
    </div>

    <router-outlet></router-outlet>
  </div>

  <footer></footer>
</ng-container>

<ng-template #signInTemplate>
  <sign-in-modal
    (hideModal)="hideModal()"
    (continueWithGoogle)="continueWith(CognitoHostedUIIdentityProvider.Google)"
    (continueWithFacebook)="continueWith(CognitoHostedUIIdentityProvider.Facebook)"
    (continueWithEmail)="continueWithEmail()"
  ></sign-in-modal>
</ng-template>

<ng-template #continueWithEmailTemplate>
  <continue-with-email-modal
    (hideModal)="hideModal()"
    (sendEmail)="sendEmail($event)"
    [activationError]="activationError"
  ></continue-with-email-modal>
</ng-template>

<ng-template #activationCodeTemplate>
  <activation-code-modal
    (validateCode)="validateNewEmailCode($event)"
    (sendActivationEmail)="sendEmail(email)"
    (hideModal)="hideModal()"
    [activation]="activation"
    [activationPassed]="activationPassed"
    [activationError]="activationError"
  ></activation-code-modal>
</ng-template>

<ng-template #loadingTemplate>
  <lead-loading-page></lead-loading-page>
</ng-template>
