<div class="mt-4 px-4 py-3 block">
  <p class="semi-bold">
    If you need any help with your flight details, just reach out to your travel agent.
  </p>
</div>

<h3 class="semi-bold mt-8 mx-4 mx-md-0">Upcoming</h3>
<ng-container *ngIf="upcomingTrips.length; else emptyTemplate">
  <a
    *ngFor="let portalLead of upcomingTrips"
    routerLink="/{{ portalLead.portalLink }}"
    class="trip-link"
    data-id="upcoming-trip"
  >
    <trip-item [portalLead]="portalLead" class="mt-4"></trip-item>
  </a>
</ng-container>

<h3 class="semi-bold mt-8 mx-4 mx-md-0">Past</h3>
<ng-container *ngIf="pastTrips.length; else emptyTemplate">
  <a
    *ngFor="let portalLead of pastTrips"
    routerLink="/{{ portalLead.portalLink }}"
    class="trip-link"
    data-id="past-trip"
  >
    <trip-item [portalLead]="portalLead" class="mt-4 inactive"></trip-item>
  </a>
</ng-container>

<ng-template #emptyTemplate>
  <p class="mt-4 mx-4 mx-md-0 text-jazlyn-700">No records found</p>
</ng-template>
