import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import * as Sentry from '@sentry/angular';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { Amplify } from '@aws-amplify/core';
import { NgxMaskModule } from 'ngx-mask';

import { awsconfig } from 'src/aws-config';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { TravelTimePipe } from '@app/pipes/travel-time.pipe';
import { HeaderComponent } from '@app/components/header/header.component';
import { FooterComponent } from '@app/components/footer/footer.component';
import { FlightInfoComponent } from '@app/components/flight-info/flight-info.component';
import { SearchingForATravelExpertComponent } from '@app/components/searching-for-a-travel-expert/searching-for-a-travel-expert.component';
import { ReviewsComponent } from '@app/components/reviews/reviews.component';
import { GetFirstNamePipe } from '@app/pipes/get-first-name.pipe';
import { OptionComponent } from '@app/components/option/option.component';
import { OptionItineraryComponent } from '@app/components/option-itinerary/option-itinerary.component';
import { SaveBadgeComponent } from '@app/components/badges/save-badge/save-badge.component';
import { BookingStatusComponent } from '@app/components/booking-status/booking-status.component';
import { OptionDetailsComponent } from '@app/components/option-details/option-details.component';
import { OptionDetailsModalComponent } from '@app/components/modals/option-details-modal/option-details-modal.component';
import { BonusFlightComponent } from '@app/components/bonus-flight/bonus-flight.component';
import { ExtraLegComponent } from '@app/components/extra-leg/extra-leg.component';
import { OvernightFlightBadgeComponent } from '@app/components/badges/overnight-flight-badge/overnight-flight-badge.component';
import { DaysShiftAfterLayoverPipe } from '@app/pipes/days-shift-after-layover.pipe';
import { HasDaysShiftAfterLayoverPipe } from '@app/pipes/has-days-shift-after-layover.pipe';
import { PassengersFormComponent } from '@app/forms/passengers-form/passengers-form.component';
import { InputFieldComponent } from '@app/forms/formly/types/input-field/input-field.component';
import { FieldWrapperComponent } from '@app/forms/formly/wrappers/field-wrapper/field-wrapper.component';
import { RepeatCardsComponent } from '@app/forms/formly/types/repeat-cards/repeat-cards.component';
import { formlyConfig } from '@app/forms/formly/formly-config';
import { DateFieldComponent } from '@app/forms/formly/types/date-field/date-field.component';
import { DropdownComponent } from '@app/ui/components/dropdown/dropdown.component';
import { FormErrorComponent } from '@app/forms/formly/form-error/form-error.component';
import { RadioFieldComponent } from '@app/forms/formly/types/radio-field/radio-field.component';
import { GoBackConfirmComponent } from '@app/components/modals/go-back-confirm/go-back-confirm.component';
import { NotFoundPageComponent } from '@app/pages/not-found-page/not-found-page.component';
import { ErrorModalComponent } from '@app/components/modals/error-modal/error-modal.component';
import { PricesTooltipComponent } from '@app/components/tooltips/prices-tooltip/prices-tooltip.component';
import { CardComponent } from '@app/ui/components/card/card.component';
import { ModalComponent } from '@app/ui/components/modal/modal.component';
import { ExtractNodesPipe } from '@app/ui/pipes/extract-nodes.pipe';
import { WithoutTimezonePipe } from '@app/ui/pipes/without-timezone.pipe';
import { SkeletonComponent } from '@app/ui/components/skeleton/skeleton.component';
import { PortalCurrencyPipe } from '@app/ui/pipes/portal-currency.pipe';
import { TooltipComponent } from '@app/ui/components/tooltip/tooltip.component';
import { LabelPipe } from '@app/ui/pipes/label.pipe';
import { RadioComponent } from '@app/ui/components/radio/radio.component';
import { ClickOutsideDirective } from '@app/ui/directives/click-outside.directive';
import { TooltipDirective } from '@app/ui/directives/tooltip.directive';
import { ModalDirective } from '@app/ui/directives/modal.directive';
import { HiddenCityTooltipComponent } from '@app/components/tooltips/hidden-city-tooltip/hidden-city-tooltip.component';
import { ThrowawayTicketingTooltipComponent } from '@app/components/tooltips/throwaway-ticketing-tooltip/throwaway-ticketing-tooltip.component';
import { HotelBokingsComponent } from '@app/components/hotel-bookings/hotel-bookings.component';
import { HotelBookingsTooltipComponent } from '@app/components/tooltips/hotel-bookings-tooltip/hotel-bookings-tooltip.component';
import { PreventTouchmoveDirective } from '@app/ui/directives/prevent-touchmove.directive';
import { HasDaysShiftPipe } from '@app/pipes/has-days-shift.pipe';
import { DropdownFieldComponent } from '@app/forms/formly/types/dropdown-field/dropdown-field.component';
import { PhonePipe } from '@app/ui/pipes/phone.pipe';
import { TermOfUseComponent } from '@app/components/term-of-use/term-of-use.component';
import { PassengersPreviewComponent } from '@app/components/passengers-preview/passengers-preview.component';
import { CardFormComponent } from '@app/forms/card-form/card-form.component';
import { CheckboxComponent } from '@app/ui/components/checkbox/checkbox.component';
import { CcNumberFieldComponent } from '@app/forms/formly/types/cc-number-field/cc-number-field.component';
import { CcExpirationFieldComponent } from '@app/forms/formly/types/cc-expiration-field/cc-expiration-field.component';
import { ProtectionSelectComponent } from '@app/components/protection-select/protection-select.component';
import { StepperComponent } from '@app/components/stepper/stepper.component';
import { CcTypePipe } from '@app/pipes/cc-type.pipe';
import { CcCvvFieldComponent } from '@app/forms/formly/types/cc-cvv-field/cc-cvv-field.component';
import { CfarSelectComponent } from '@app/components/cfar-select/cfar-select.component';
import { PriceSummaryComponent } from '@app/components/price-summary/price-summary.component';
import { GetCountryByIdPipe } from '@app/pipes/get-country-by-id.pipe';
import { DotSeparatorComponent } from '@app/ui/components/dot-separator/dot-separator.component';
import { HiddenFlightsModalComponent } from '@app/components/modals/hidden-flights-modal/hidden-flights-modal.component';
import { AskToSignInModalComponent } from '@app/components/modals/ask-to-sign-in-modal/ask-to-sign-in-modal.component';
import { SignInModalComponent } from '@app/components/modals/sign-in-modal/sign-in-modal.component';
import { LoginPageComponent } from '@app/pages/login-page/login-page.component';
import { LogoutPageComponent } from '@app/pages/logout-page/logout-page.component';
import { VerificationModalComponent } from '@app/components/modals/verification-modal/verification-modal.component';
import { OldLeadPageComponent } from '@app/pages/old-lead-page/old-lead-page.component';
import { LeadPageComponent } from '@app/pages/lead-page/lead-page.component';
import { PassengersPageComponent } from '@app/pages/passengers-page/passengers-page.component';
import { LeadWrapperPageComponent } from '@app/pages/lead-wrapper-page/lead-wrapper-page.component';
import { LeadLoadingPageComponent } from '@app/pages/lead-loading-page/lead-loading-page.component';
import { ThankYouPageComponent } from '@app/pages/thank-you-page/thank-you-page.component';
import { PremiumDisruptionAssistancePageComponent } from '@app/pages/premium-disruption-assistance-page/premium-disruption-assistance-page.component';
import { CancelForAnyReasonPageComponent } from '@app/pages/cancel-for-any-reason-page/cancel-for-any-reason-page.component';
import { GratitudePageComponent } from '@app/pages/gratitude-page/gratitude-page.component';
import { OverviewAndPaymentPageComponent } from '@app/pages/overview-and-payment-page/overview-and-payment-page.component';
import { FormatMsPipe } from '@app/ui/pipes/format-ms.pipe';
import { ContinueWithEmailModalComponent } from '@app/components/modals/continue-with-email-modal/continue-with-email-modal.component';
import { ActivationCodeModalComponent } from '@app/components/modals/activation-code-modal/activation-code-modal.component';
import { TravelExpertInfoComponent } from '@app/components/travel-expert-info/travel-expert-info.component';
import { HomePageComponent } from '@app/pages/home-page/home-page.component';
import { TabComponent } from '@app/ui/components/tab/tab.component';
import { TripItemComponent } from '@app/components/trip-item/trip-item.component';
import { InfoBlockFieldComponent } from '@app/forms/formly/types/info-block-field/info-block-field.component';
import { CurrencyFieldComponent } from '@app/forms/formly/types/currency-field/currency-field.component';
import { PriceDropProtectionPageComponent } from '@app/pages/price-drop-protection-page/price-drop-protection-page.component';
import { PriceDropSelectComponent } from '@app/components/price-drop-select/price-drop-select.component';
import { CardWrapperComponent } from '@app/forms/formly/wrappers/card-wrapper/card-wrapper.component';
import { InfoBlockComponent } from '@app/ui/components/info-block/info-block.component';
import { GetMaxBalanceAmountPipe } from '@app/pipes/get-max-balance-amount.pipe';
import { GetTotalAmountPipe } from '@app/pipes/get-total-amount.pipe';
import { QuotesPageComponent } from '@app/pages/quotes-page/quotes-page.component';
import { BookingsAndTripsPageComponent } from '@app/pages/bookings-and-trips-page/bookings-and-trips-page.component';
import { StripeFormComponent } from '@app/forms/stripe-form/stripe-form.component';
import { ProductBadgeComponent } from '@app/components/badges/product-badge/product-badge.component';

Amplify.configure(awsconfig);

const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    ModalComponent,
    ExtractNodesPipe,
    TravelTimePipe,
    WithoutTimezonePipe,
    SkeletonComponent,
    NotFoundPageComponent,
    PortalCurrencyPipe,
    ErrorModalComponent,
    TooltipComponent,
    LabelPipe,
    HeaderComponent,
    FooterComponent,
    FlightInfoComponent,
    SearchingForATravelExpertComponent,
    ReviewsComponent,
    GetFirstNamePipe,
    OptionComponent,
    OptionItineraryComponent,
    SaveBadgeComponent,
    BookingStatusComponent,
    OptionDetailsComponent,
    OptionDetailsModalComponent,
    BonusFlightComponent,
    ExtraLegComponent,
    PricesTooltipComponent,
    OvernightFlightBadgeComponent,
    DaysShiftAfterLayoverPipe,
    HasDaysShiftAfterLayoverPipe,
    PassengersFormComponent,
    InputFieldComponent,
    FieldWrapperComponent,
    RepeatCardsComponent,
    RadioComponent,
    DateFieldComponent,
    DropdownComponent,
    ClickOutsideDirective,
    FormErrorComponent,
    RadioFieldComponent,
    GoBackConfirmComponent,
    TooltipDirective,
    ModalDirective,
    HiddenCityTooltipComponent,
    ThrowawayTicketingTooltipComponent,
    HotelBokingsComponent,
    HotelBookingsTooltipComponent,
    PreventTouchmoveDirective,
    HasDaysShiftPipe,
    DropdownFieldComponent,
    PhonePipe,
    TermOfUseComponent,
    PassengersPreviewComponent,
    CardFormComponent,
    CheckboxComponent,
    CcNumberFieldComponent,
    CcExpirationFieldComponent,
    ProtectionSelectComponent,
    StepperComponent,
    CcTypePipe,
    CcCvvFieldComponent,
    CfarSelectComponent,
    PriceSummaryComponent,
    GetCountryByIdPipe,
    DotSeparatorComponent,
    HiddenFlightsModalComponent,
    AskToSignInModalComponent,
    SignInModalComponent,
    LoginPageComponent,
    LogoutPageComponent,
    VerificationModalComponent,
    OldLeadPageComponent,
    LeadPageComponent,
    PassengersPageComponent,
    LeadWrapperPageComponent,
    LeadLoadingPageComponent,
    ThankYouPageComponent,
    PremiumDisruptionAssistancePageComponent,
    CancelForAnyReasonPageComponent,
    GratitudePageComponent,
    OverviewAndPaymentPageComponent,
    FormatMsPipe,
    ContinueWithEmailModalComponent,
    ActivationCodeModalComponent,
    TravelExpertInfoComponent,
    HomePageComponent,
    TabComponent,
    TripItemComponent,
    InfoBlockFieldComponent,
    CurrencyFieldComponent,
    PriceDropProtectionPageComponent,
    PriceDropSelectComponent,
    CardWrapperComponent,
    InfoBlockComponent,
    GetMaxBalanceAmountPipe,
    GetTotalAmountPipe,
    QuotesPageComponent,
    BookingsAndTripsPageComponent,
    StripeFormComponent,
    ProductBadgeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(formlyConfig),
    CreditCardDirectivesModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    DaysShiftAfterLayoverPipe,
    PortalCurrencyPipe,
    GetMaxBalanceAmountPipe,
    ...sentryProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
