import { Pipe, PipeTransform } from '@angular/core';

import { State } from '@app/services/state/state.service';

@Pipe({
  name: 'getTotalAmount',
})
export class GetTotalAmountPipe implements PipeTransform {
  transform(soldAmount = 0, state: State) {
    return (
      soldAmount +
      (state.disruptionProtection || 0) +
      (state.tipsAmount || 0) +
      (state.cancelForAnyReason || 0) +
      (state.priceDropProtection || 0)
    );
  }
}
