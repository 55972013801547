<ng-container *ngIf="quotes.length; else emptyTemplate">
  <a
    *ngFor="let portalLead of quotes"
    routerLink="/{{ portalLead.portalLink }}"
    class="trip-link"
    data-id="quote"
  >
    <trip-item [portalLead]="portalLead" class="mt-4"></trip-item>
  </a>
</ng-container>

<ng-template #emptyTemplate>
  <p class="mt-4 mx-4 mx-md-0 text-jazlyn-700">No records found</p>
</ng-template>
