import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { PortalLeadNode } from '@app/services/api/api.types';
import { StateService } from '@app/services/state/state.service';

@Component({
  selector: 'quotes-page',
  templateUrl: './quotes-page.component.html',
  styleUrls: ['./quotes-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesPageComponent implements OnInit {
  quotes: PortalLeadNode[];

  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.quotes = this.stateService.getAuthorizedData().quotes || [];

    const lead_portal_link = this.stateService.getState().portalLink;
    window.track({ event_name: 'quotes_page_opened', lead_portal_link });
  }
}
